import React from "react"
import { Link } from "gatsby"

const Header = ({ siteTitle, isHomepage = false }) => (
  <div className="header">
    <h1>
      <Link
        to="/"
        style={{
          textDecoration: "none",
          display: "inline-block",
          fontFamily: "'Playfair Display', serif",
        }}
        title="Go to The Recycled Art Class home page"
      >
        {siteTitle}
      </Link>
    </h1>
    {isHomepage && (
      <nav>
        <ul style={{ margin: 0, padding: 0 }}>
          <li style={{ display: "inline" }}>
            <Link to="/about/">About the class</Link>
          </li>
          <li style={{ display: "inline" }}>
            <a href="https://www.instagram.com/recycledartclass/">
              Follow on Instagram
            </a>
          </li>
        </ul>
      </nav>
    )}
  </div>
)

export default Header
